import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
// import vuetify from './plugins/vuetify'
import {BootstrapVue, BootstrapVueIcons} from "bootstrap-vue";
// import moment from 'moment-timezone'
// Vue.use(require('vue-moment'));
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueSweetalert2 from 'vue-sweetalert2';
// import Select2Component
// import Select2 from 'v-select2-component';
// Vue.component('Select2', Select2);
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);
 
Vue.use(VueMoment, {
    moment,
})
// Vue.use(require('vue-moment'));

// moment.tz('Asia/Jakarta')
// console.log(Vue.moment().locale()) 

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LcyLeYpAAAAAL5RS1Td4W3h_e87cnE5jPAFfJYy" });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import { setHeaderToken } from "./utils/auth";
const token = localStorage.getItem("token");

if (token) {
  setHeaderToken(token);
}

Vue.config.productionTip = false

store.dispatch("get_user", token).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
  });
  router.beforeEach((to, from, next) => {
    const publicPages = ["/login", "/register","/Monitor/:klinik"];
    // const Monitor = "Monitor/:klinik";
    const authRequired = !publicPages.includes(to.matched[0].path);
    // const isMonitor = (to.name==Monitor)? true:false;
    const loggedIn = localStorage.getItem("token");
    // trying to access a restricted page + not logged in
    // redirect to login page
    // if(){
    //   next()
    // }
    // else
     if ((authRequired && !loggedIn )) {
      next("/login");
      // console.log(Monitor.includes(to.path, 0));
      // console.log(to.params);
      // console.log(to.matched[0].path);
    } else {
      next();
    }
  });
