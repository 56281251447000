<template>

  <router-view></router-view>

</template>
<script>

export default {
name: 'App',

data: () => ({
  //
}),
mounted() {
        $('[data-widget="treeview"]').Treeview('init');

    }
};
</script>
