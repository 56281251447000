//auth.js
import { setHeaderToken } from '../utils/auth'
import { mainAxios } from '../utils/apiconf'
export default {
    state: {
        user: null,
        isLoggedIn: false,
    },
    mutations: {
        set_user(state, data) {
            state.user = data
            state.isLoggedIn = true
        },
        reset_user(state) {
            state.user = null
            state.isLoggedIn = false
        }
    },
    getters: {
        isLoggedIn(state) {
            return state.isLoggedIn
        },
        user(state) {
            // console.log(state);
            return state.user
        }
    },
    actions: {
        login({ dispatch, commit }, data) {
            return new Promise((resolve, reject) => {
                mainAxios.post('Auth/Authentication', data)
                    .then(response => {
                        console.log(response);
                        if(response.status =='200'){

                            const token = response.data.token
                            localStorage.setItem('token', token)
                            // console.log(response.data);
                            response.data.setting.forEach(element => {
                                if(element.active == '1'){
                                    localStorage.setItem( element.service_name, true)
                                }
                            })
                            setHeaderToken(token)
                            dispatch('get_user')
                        }
                        resolve(response)
                    })
                    .catch(err => {
                        commit('reset_user')
                        localStorage.removeItem('token')
                        reject(err)
                    })
            })
        },
        async get_user({ commit }) {
            if (!localStorage.getItem('token')) {
                // console.log('asy:' + commit);
                return
            }
            try {
                let response = await mainAxios.get('me')
                commit('set_user', response.data)
                // console.log(response.data);
                // console.log(response.status);
                // if(response.data.status == 'Token is Expired'){
                //     commit('reset_user')
                // // removeHeaderToken()
                // localStorage.removeItem('token')
                // delete mainAxios.defaults.headers.common["Authorization"];
                // }
                if(response.data.code !== 200){
                    localStorage.removeItem('token')
                    delete mainAxios.defaults.headers.common["Authorization"];
                }
            } catch (error) {
                commit('reset_user')
                // removeHeaderToken()
                localStorage.removeItem('token')
                delete mainAxios.defaults.headers.common["Authorization"];
                return error
            }
        },
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('reset_user')
                localStorage.removeItem('token')
                localStorage.clear()
                // removeHeaderToken()
                
                resolve()
            })
        }
    }
}
